import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import VideoSec from '../../components/VideoSec';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import Service from '../../components/Service';
import SvrhaUdruge from '../../components/SvrhaUdruge';
import FunFact from '../../components/FunFact';
import CauseSection from '../../components/CauseSection';
import TeamSection from '../../components/TeamSection';
import PartnerSection from '../../components/PartnerSection';



const SvrhaUdrugePage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Svrha osnivanja udruge'} pagesub={'O nama'} />
            {/* <VideoSec/> */}

            <SvrhaUdruge />
            <Service Fclass={'wpo-features-section-s2'} />
            {/* <FunFact/> */}
            {/* <CauseSection/>
            <TeamSection/>
            <PartnerSection tNone={'title-none'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default SvrhaUdrugePage;
