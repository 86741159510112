import React from 'react'
import shape from '../../images/cta-shape.png'


const FunFact = (props) => {

    const funfact = [
        {
            title: '2.759.039',
            subTitle: 'hektara Republike Hrvatske je šumsko zemljište',
        },
        {
            title: '49,3%',
            subTitle: 'kopnene površine RH je obraslo šumom',
        },
        {
            title: '8',
            subTitle: 'Nacionalnih parkova',
        },
        {
            title: '410',
            subTitle: 'Zaštićenih prirodnih područja',
        },
    ]

    return (
        <section className="wpo-fun-fact-section" style={{paddingBottom: "120px"}}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-fun-fact-grids clearfix">
                            {funfact.map((funfact, fitem) => (
                                <div className="grid" key={fitem}>
                                    <div className="info">
                                        <h3>{funfact.title}</h3>
                                        <p>{funfact.subTitle}</p>
                                    </div>
                                </div>
                            ))}
                            <div className="shape"><img src={shape} alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FunFact;