import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'


export default class Header extends Component {

    state = {
        isSearchShow: false,
    }

    searchHandler = () => {
        this.setState({
            isSearchShow: !this.state.isSearchShow
        })
    }

    render() {
        // const { isSearchShow } = this.state;

        // const SubmitHandler = (e) => {
        //     e.preventDefault()
        // }

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }
        return (
            <header id="header" className={this.props.topbarNone}>
                <div className={`wpo-site-header ${this.props.hclass}`}>
                    <nav className="navigation navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                    <div className="mobail-menu">
                                        <MobileMenu />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-6">
                                    <div className="navbar-header">
                                        {/* <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={this.props.Logo}
                                            alt="" /></Link> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-1 col-1">
                                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                        <button className="menu-close"><i className="ti-close"></i></button>
                                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                                            <li className="menu-item-has-children">
                                                <Link onClick={ClickHandler} to="/">Početna</Link>

                                            </li>
                                            <li className="menu-item-has-children">
                                                <Link to="/about">O nama</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/about">Šumska pedagogija</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/team">Udruga Silva.Rei</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/mission">Svrha osnivanja udruge</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/goals">Ciljevi udruge</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/tasks-2022-2023">Program rada za 2023.</Link></li>

                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <Link to="/blog">Objave</Link>
                                                {/* <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/blog">Novosti</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog">Događanja</Link></li> 
                                                </ul> */}
                                            </li>
                                            {/* <li className="menu-item-has-children">
                                                <Link to="/">Edukacija</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/service">Service</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/service-single/1">Service Single</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/project">Project</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/project-single/1">Project Single</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/donate">Donate</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/volunteer">Volunteer</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/testimonial">Testimonial</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/404">Error 404</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/login">Login</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/register">Sign Up</Link></li>
                                                </ul>
                                            </li> */}
                                            {/* <li className="menu-item-has-children">
                                                <Link onClick={ClickHandler} to="/blog">Blog</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/blog">Blog right sidebar</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                                                    <li className="menu-item-has-children">
                                                        <Link onClick={ClickHandler} to="/">Blog details</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link onClick={ClickHandler} to="/blog-single/1">Blog details right sidebar</Link>
                                                            </li>
                                                            <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/1">Blog details left
                                                                sidebar</Link></li>
                                                            <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/1">Blog details
                                                                fullwidth</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li> */}
                                            <li><Link onClick={ClickHandler} to="/contact">Kontakt</Link></li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-2 col-2">
                                    <div className="header-right">
                                        <div className="close-form">
                                            {/* <Link onClick={ClickHandler} className="theme-btn" to="#">Prijava</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav >
                </div >
            </header >
        )
    }
}
