import React from 'react'
// import { Link } from 'react-router-dom'
import abimg from '../../images/about6.jpg'


const Tasks2022 = (props) => {

    return (
        <section className="wpo-about-section-s6 section-padding">
            <div className="container">
                <div className="row align-items-center">

                    <div className="col-12">
                        <div className="wpo-about-text" style={{maxWidth: "none"}}>
                            <span>Udruga "Silva.Rei"</span>
                            <h2>Program rada za 2023. godinu</h2>
                            <h3>1. Popularizacija šumske pedagogije (ŠP) i povećanje vidljivosti udruge/Centra</h3>
                            <ul>
                                <li>Organizacija demonstracijskih radionica i posebnih događanja (svečanosti podjele potvrda i dr.) </li>
                                <li>Predstavljanje ŠP na skupovima (konferencije, simpoziji, seminari i dr.)</li>
                                <li>Izrada web portala, objave na društvenim mrežama i dr.</li>
                            </ul>
                            <h3>2. Formiranje edukacijskog tima i uspostava sustava seminara za ŠP</h3>
                            <ul>
                                <li>Formiranje i osposobljavanje edukacijskog tima</li>
                                <li>Priprema kurikuluma za seminare ŠP</li>
                                <li>Izrada osnovnog kataloga aktivnosti ŠP </li>
                            </ul>
                            <h3>3. Provedba edukacijskih seminara </h3>
                            <ul>
                                <li>Organizacija timova i uspostava modela terenskog izvođenja seminara </li>
                                <li>Komunikacija s kandidatima/pristupnicima na seminare ŠP</li>
                                <li>Izvođenje predavanja i terenskih demonstracija</li>
                                <li>Ocjenjivanje i potvrđivanje pristupnika</li>
                                <li>Ocjena uspješnosti i izvještavanje</li>
                            </ul>
                            <h3>4. Uspostava koncepta i osiguranje kvalitete šumske pedagogije u Hrvatskoj</h3>
                            <ul>
                                <li>Razvoj međunarodno standardiziranog koncepta ŠP u Hrvatskoj </li>
                                <li>Sudjelovanje u radnoj skupini za ŠP pri Ministarstvu poljoprivrede</li>
                                <li>Uspostava sustava osposobljavanja i potvrđivanja kompetencija za ŠP na nacionalnoj razini</li>
                            </ul>
                            <h3>5. Omasovljenje udruge, razvoj suradničke mreže i međunarodno povezivanje</h3>
                            <ul>
                                <li>Pridruživanje novih članova</li>
                                <li>Sklapanje ugovora o suradnji i/ili partnerstvu s pravnim i fizičkim osobama</li>
                                <li>Uključivanje u međunarodne institucije, asocijacije i forume koje se bave problematikom šumske pedagogije </li>
                            </ul>
                            <h3>6. Razvoj i jačanje kapaciteta udruge /Centra</h3>
                            <ul>
                                <li>Digitalizacija funkcioniranja i poslovanja udruge/Centra</li>
                                <li>Standardizacija administrativnih poslova i financijskih funkcija</li>
                            </ul>
                            {/*                           
                            <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">More About</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tasks2022;