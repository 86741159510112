import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'
import timg5 from '../images/team/5.jpg'
import timg6 from '../images/team/6.jpg'
import timg7 from '../images/team/7.jpg'
import timg8 from '../images/team/8.jpg'


const Teams = [

   {
      id: '1',
      tImg: timg1,
      name: 'mr. sp. Dragan Turk',
      title: 'dipl. ing. šum.',
      description: "stručni voditelj zaštite, očuvanja i korištenja u NP Risnjak; prethodno glavni čuvar prirode u NP Risnjak.",
      notes: [
         "više od dvadeset godina u različitim projektima edukacije i interpretacije u zaštiti prirode i okoliša.",
         "s najvećim iskustvom prakse šumske pedagogije u Hrvatskoj.",
         "kroz seminar u okviru IPA Sožitje/Suživot (2012.) osposobio se za šumskog pedagoga; od tada priprema i vodi program šumske pedagogije u NP Risnjak za različite ciljne skupine, uključujući i stručnjake iz drugih zaštićenih područja u Hrvatskoj.",
         "popularizira šumsku pedagogiju kroz demonstracijske radionice koje provodi širom Hrvatske dočaravajući na autentičnim šumskim lokalitetima mogućnosti korištenja raznih osjetila u doživljaju šume i prirode."
      ]
   },
   {
      id: '2',
      tImg: timg2,
      name: 'Martina Jurjević Varga',
      title: 'dipl. ing. šum.',
      description: "glavna čuvarica i rukovoditeljica Odjela nadzora i tehničkih poslova u Javnoj ustanovi Park prirode Medvednica.",
      notes: [
         "gotovo čitavu profesionalnu karijeru radi u zaštiti prirode na različitim poslovima od nadzornice, suradnice za šumarstvo, preko voditeljice edukacije do glavne čuvarice prirode. ",
         "završila je trening tečaj interpretacije i edukacije prirode.",
         "sudjeluje u osmišljavanju i izvođenju edukativnih programa PP Medvednica Šuma oko nas za različite ciljne skupine: Što da sam stablo? • Proizvodi i plodovi iz šume • Šumska pustolovina” • Listopadna šuma i dr. ",
         "Rečenicu Johna Muira: Into the forest I go to lose my mind and find my soul smatra najljepšom izrekom o utjecaju prirode na čovjeka"
      ]
   },
   {
      id: '3',
      tImg: timg3,
      name: 'Iva Murgić Lepoglavec',
      title: 'mag. ing. silv.',
      description: "stručna suradnica - edukatorica u Javnoj ustanovi Park prirode Medvednica",
      notes: [
         "početke u ekološkoj edukaciji ostvaruje kao student u Pećinskom parku Grabovača u Perušiću, gdje je radi kao stručna vodičica, voditeljica ekoloških radionica i mentor na projektima europske volonterske službe i Europskih snaga solidarnosti s ciljem osvješćivanja mladih o zaštiti prirode i okoliša.",
         "aktualno je edukator u Centru za posjetitelje Medvedgrad gdje svakodnevno ima priliku kroz interaktivni postav Centra i edukativne radionice prenijeti važnost šume, koja je temeljni biološki fenomen PP Medvednica, te zaštite prirode i okoliša velikom broju posjetitelja."
      ],

   },
   {
      id: '4',
      tImg: timg4,
      name: 'Irena Mišković',
      title: 'dipl. ing. šum.',
      description: "revirnica u Šumariji Pula, Hrvatske šume, UŠP Buzet",
      notes: [
         "ekološkom edukacijom bavi se od 2012. uključenjem u program Hrvatskih šuma d.o.o. „Škola u šumi, šuma u školi“ u kojem je održala veliki broj edukativnih radionica za osnovne škole i dječje vrtiće u Istri.",
         "kao vanjski suradnik radila je na GLOBE projektu, programu zavičajne nastave Škole za turizam i ugostiteljstvo Pula i Škole za odgoj i obrazovanje, „Vrtić u prirodi“ za DV „Mali svijet” gdje kroz suradnju nastaje priručnik za pješačenje vrtićke djece u prirodi „Šumske ideje ispod pokrivača od lišća“.",
         "osposobljavala se na tečajevima šumske pedagogije u organizaciji Inštituta za gozdno pedagogiko (Slovenija).",
         "od 2020. godine autor je i voditelj programa ekološke edukacije djece u Natura 2000 području #Luka Budava-Istra.",
         "aktualno pohađa program za stjecanje pedagoških kompetencija na Pedagoškom fakultetu Sveučilišta u Puli."
      ]
   },
   {
      id: '5',
      tImg: timg5,
      name: 'Milan Ječmenica',
      title: 'dipl. ing. šum.',
      description: "nastavnik strukovnih predmeta u Drvodjeljskoj školi u Zagrebu",
      notes: [
         "u usmjerenju Šumarski tehničar i Tehničar zaštite prirode kroz nastavu obrađuje sadržaje iz botanike, pedologije, ekologije, lovstva i uzgajanja šuma.",
         "posebno se bavi transferom znanja o načelima potrajnosti gospodarenja šumama u Hrvatskoj i zakonitosti razvoja šumskog ekosustava.",
         "u okviru programa ASOO-a ‘Nadogradnja šumarske strukovne nastave i prakse integriranjem novih područja znanja’ (2020.) odslušao webinar  „Budi voditelj šumskih radionica!“ koji je uključio izradu projektnog zadatka i pismenu provjeru znanja."
      ]
   },
   {
      id: "6",
      tImg: timg6,
      name: 'mr. sc. Irena Franjić',
      title: 'prof. biol. i kem.',
      description: "stručna suradnica za komunikacije u Sektoru za zelenu energiju i  projekte sufinancirane sredstvima EU fondova i međunarodne projekte u Hrvatske šume d.o.o Zagreb.",
      notes: [
         "2009. pokrenula projekt Škola u šumi, šuma u školi u Upravi šuma Zagreb s ciljem približavanja šume i šumarske struke djeci, te integriranje istog u odgojno-obrazovni proces;  projekt dobiva odobrenje Agencije za odgoj i obrazovanje i MZOS i širi se po svim upravama šuma Hrvatskih šuma.",
         "autorica je tri poučne staze u pripremljene s lokalnim osnovnim školama, te dvije zelene učionice: „Šarov jarek“ u Brestovcu Orehovičkom i „Malo Sljeme“ koju u programu škole u prirodi obilaze zagrebačke  osnovne škole.",
         "sudionica 14. Europskom kongresu šumske pedagogije u Rigi (Latvija, 2019.). ",
         "aktualno aktivno sudjeluje u programima edukacije za školsku djecu i ostale ciljne skupine u okviru projekta Naturavita."
      ]

   },
   {
      id: "7",
      tImg: timg8,
      name: 'mr. sc. Goran Gregurović',
      title: 'dipl. ing. šum.',
      description: "voditelj Odjela za EU programe i fondove u šumarstvu pri Ministarstvu poljoprivrede RH",
      notes: [
         "tijekom studiranja dodatno se osposobljavao u području urbanog šumarstva kroz naobrazbu i stručnu praksu u Njemačkoj.",
         "bavi se i hortikulturnom djelatnošću, pri čemu je organizaciju i rad izučavao je u najstarijoj vrtlarskoj službi na svijetu, Vatikanskim vrtovima (Giardini vaticani) i u Gartenbauamt  u Nürnbergu.",
         "ima 4 godišnje iskustvo kreiranja i izvođenja predmeta 'Green Fingers' s obuhvatom tema iz povijesti vrtne umjetnosti, ekologije, zaštite prirode i urbanog šumarstva.",
         "završio je edukaciju za trenera šumske kupke (Waldbaden Trainer) u Austriji.",
         "zadnjih godina sustavno radi na popularizaciji šumske terapije u Hrvatskoj."
      ]
   },
   {
      id: '8',
      tImg: timg7,
      name: 'prof. dr. sc. Ivan Martinić',
      title: 'dipl. ing. šum.',
      description: "sveučilišni profesor na studijima Šumarstvo i Urbano šumarstvo, zaštita prirode i okoliša na Fakultetu šumarstva i drvne tehnologije Sveučilišta u Zagrebu",
      notes: [
         "autor i izvoditelj desetak projekata ekološke edukacije u NP Risnjak, NP Krka, NP Plitvička jezera i drugim zaštićenim područjima Hrvatske.",
         "autor i voditelj više od 30-tak seminara i terenskih radionica za osnovne škole, dječje vrtiće,  obrazovne agencije i za javne ustanove koje upravljaju zaštićenim prirodnim vrijednostima.",
         "za ekološku edukaciju osposobljavao se i kroz studijsko putovanje u SAD (gost National Park Service-a u okviru programa suradnje USAID-a i Hrvatske.",
         "autor više priručnika, edukativnih aplikacija (e-kviz 'Šumarko') i materijala za edukaciju i interpretaciju na temu šume i šumarstva.",
         "autor koncepta i voditelj programa osposobljavanja i potvrđivanja mladih čuvara prirode od kojih se program 'Ptiček' u Krapinsko-zagorskoj županiji neprekidno provodi od 2008. godine.",
         "inicijator osnivanja Centra za šumsku pedagogiju Silva.Rei - prve udruge za promicanje i razvoj koncepta šumske pedagogije u Hrvatskoj. "
      ]
   },

]

export default Teams;