import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
// import CauseSection from '../../components/CauseSection';
import { Link } from 'react-router-dom';

const TeamPage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'O nama'} pagesub={'Udruga'} />
            {/* <CauseSection/> */}
            <section className="wpo-about-section-s2 section-padding ">
                <div className="container">

                    <div className="row align-items-center">

                        <div className="col-12">
                            <div className="wpo-about-text" style={{ maxWidth: "none" }}>

                                <span>Podaci o udruzi</span>
                                <h2>Udruga Silva.Rei</h2>
                                <ul style={{ marginLeft: "60px" }}>
                                    <li>Naziv udruge: <strong>Centar za šumsku pedagogiju Silva.Rei Zagreb</strong></li>
                                    <li>Matični broj: 5645999</li>
                                    <li>OIB udruge: 72383284772</li>
                                    <li>Poslovni račun: HR4423600001103019170 (Zagrebačka banka)</li>
                                    <li>osnovana u Zagrebu, u lipnju 2022. godine</li>
                                    <li>djeluje u područjima obrazovanja, znanosti i istraživanja, zaštite  prirode i okoliša te održivog razvoja</li>
                                    <li>14 visokoobrazovanih stručnjaka, od kojih čak osam s potvrđenim statusom šumskih pedagoga</li>
                                </ul>

                                <h3>Upravni odbor udruge</h3>
                                <ul style={{ marginLeft: "60px" }}>
                                    <li>Goran Gregurović</li>
                                    <li>Martina Jurjević Varga</li>
                                    <li>Ivan Martinić</li>
                                    <li>Irena Mišković</li>
                                    <li>Dragan Turk</li>
                                </ul>
                                <h3>Predsjednik udruge – voditelj Centra</h3>
                                <ul style={{ marginLeft: "60px" }}>
                                    <li>Ivan Martinić</li>
                                </ul>
                                <h3>Tajništvo udruge</h3>
                                <ul style={{ marginLeft: "60px" }}>
                                    <li>Irena Franjić</li>
                                </ul>
                                <div style={{textAlign: "center"}}>
                                    <Link to="/contact" className="theme-btn-s2" >Kontaktirajte nas</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TeamPage;

