import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import { useParams } from 'react-router-dom'
import Teams from '../../api/team';
import Footer from '../../components/footer'
import Logo from '../../images/logo.png'


const TeamSinglePage = (props) => {
    const { id } = useParams()

    const TeamSingle = Teams.find(item => item.id === id)

    const SubmitHandler = (e) => {
        e.preventDefault()
    }



    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={TeamSingle.name} pagesub={TeamSingle.title} />
            <div className="attorney-pg-area section-padding">
                <div className="container">
                    <div className="attorney-info-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="attorney-info-img">
                                    <img src={TeamSingle.tImg} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="attorney-info-text">
                                    <h2>{TeamSingle.name}, {TeamSingle.title}</h2>
                                    <p style={{fontSize: 18}}>{TeamSingle.description}</p>
                                    {/* <ul>
                                        {TeamSingle.notes.map((a, i) => <li key={i}>{a}</li>)}

                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="exprience-area" style={{paddingTop: 0}}>
                        <div className="row">
                            <div className="col-lg-12">
    
                                <div className="education-area ex-wiget" style={{maxWidth: "100%"}}>
                                    <h2>Iskustvo</h2>
                                    <ul>
                                    {TeamSingle.notes.map((a, i) => <li key={i}>{a}</li>)}
                                    </ul>
                                </div>
                                {/* <div className="language-area ex-wiget">
                                    <h2>Language</h2>
                                    <ul>
                                        <li>French(fluent), English (fluent), Greek , chinese.</li>
                                    </ul>
                                </div> */}
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TeamSinglePage;
