import prj1 from '../images/project/1.jpg'
import prj2 from '../images/project/2.jpg'
import prj3 from '../images/project/3.jpg'
import prj4 from '../images/project/4.jpg'
import prj5 from '../images/project/5.jpg'
import prj6 from '../images/project/6.jpg'

import prwj1 from '../images/project/7.jpg'
import prwj2 from '../images/project/11.jpg'
import prwj3 from '../images/project/9.jpg'
import prwj4 from '../images/project/10.jpg'
import prwj5 from '../images/project/8.jpg'

import propj1 from '../images/project/12.jpg'
import propj2 from '../images/project/13.jpg'
import propj3 from '../images/project/14.jpg'
import propj4 from '../images/project/15.jpg'
import propj5 from '../images/project/16.jpg'

import prontj1 from '../images/project/17.jpg'
import prontj2 from '../images/project/19.jpg'
import prontj3 from '../images/project/20.jpg'
import prontj4 from '../images/project/21.jpg'
import prontj5 from '../images/project/22.jpg'
import prontj6 from '../images/project/18.jpg'

import primg1 from '../images/project-single/2.jpg'
import primg2 from '../images/project-single/3.jpg'

import prwimg1 from '../images/project-single/4.jpg'
import prwimg2 from '../images/project-single/5.jpg'

import propimg1 from '../images/project-single/6.jpg'
import propimg2 from '../images/project-single/7.jpg'

import pront1 from '../images/project-single/nt1.jpg'
import pront2 from '../images/project-single/nt2.jpg'


const Projects = [
  {
    id: '1',
    projectImg: prj1,
    title: "School In Africa",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: '2',
    projectImg: prj2,
    title: "School In Ghana",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: '3',
    projectImg: prj3,
    title: "School In Nigeria",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: '4',
    projectImg: prj4,
    title: "School In Uganda",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: '5',
    projectImg: prj5,
    title: "School In India",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: '6',
    projectImg: prj6,
    title: "School In PNG",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: '7',
    projectImg: prwj1,
    title: "Sundarbans Royel Tiger",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: '8',
    projectImg: prwj2,
    title: "Australian Camel",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: '9',
    projectImg: prwj3,
    title: "African Macaw Bird",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: '10',
    projectImg: prwj4,
    title: "US Deer",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: '11',
    projectImg: prwj5,
    title: "African Elephant",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: '12',
    projectImg: propj1,
    title: "Save Ocean",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: '13',
    projectImg: propj2,
    title: "Save Ocean",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: '14',
    projectImg: propj3,
    title: "Save Ocean",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: '15',
    projectImg: propj4,
    title: "Save Ocean",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: '16',
    projectImg: propj5,
    title: "Save Ocean",
    dec: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: '17',
    projectImg: prontj1,
    title: 'Šumska pedagogija – učenja o svijetu u šumi, u stvarnom okruženju',
    dec: 'Ideja da djeca uče o svijetu u šumi, na licu mjesta, jedno od drugoga i iz vlastitog iskustva dobrim je dijelom suprotstavljena pojmu podizanja djece koji uključuje roditeljsku strepnju, posvemašnju zaštitu i podmetanje leđa, od pelena do zreloga doba. U knjizi "Posljednje dijete u šumi" (Last Child in the Woods, 2015), američki autor Michael Louv govori o "poremećaju nedostatka prirode" - smanjeno korištenje čula, dekoncentriranost, veća stopa fizičkih i emotivnih bolesti, pretilost, nesposobnost procjene rizika za sebe i druge… Odgovori na takve poremećaje uvelike su sadržani u konceptu šumske pedagogije.',
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: '18',
    projectImg: prontj2,
    title: 'Učenje "iz šume u šumi" ili šumsko obrazovanje',
    dec: 'Učenje o šumi usko je povezano s potrebom iskustvenog učenja izvan učionica. Učenje o šumi i u šumi (kolokvijalno „šumsko obrazovanje“) temelji se na pristupu  da se šumu treba doživjeti kroz iskustvo srca, glave i ruku; šumu treba doživjeti svim osjetilima, mora je se osjetiti, što znači vidjeti, slušati, dodirnuti, mirisati. Postoji i mnogo toga u šumi što se može kušati. Kod djece treba probuditi zanimanje da šumu dožive i osjete kroz igru i tako steknu iskustva važna za njihov emocionalni i fizički razvoj. Kroz učenje u šumi moguće je povezati gotovo sve školske predmete. Znanje i iskustvo o strukturi i funkcioniranju prirodnih sustava može se koristiti i za prednosti u svim drugim predmetima, kao što su hrvatski jezik, povijest, zemljopis, glazba, umjetničko obrazovanje, kao i za apstraktne predmete poput matematike ili fizike.',
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: '19',
    projectImg: prontj3,
    title: 'Zašto je šuma istinska učionica?',
    dec: 'Diljem svijeta šume čine jedan od najčešćih i najraznolikijih ekosustava kopnenih područja i izvrstan su primjer suodnosa u prirodi. Kao prostor učenja, ali i objekt razumijevanja, šuma je često sastavni dio školskog gradiva. Upravo je šuma, iz perspektive održivosti, savršeni primjer – jer je vječna a istovremeno uništiva! Mnogi ljudi kako prije, tako i sada promatraju šumu kao personifikaciju prirode. Zapravo ni jedna druga životna zajednica nije toliko pogodna za neposredni doživljaj prirode, neograničeno pristupačna, a ljudima uz čisto prirodno-znanstvenu omogućuje i estetsku te emocionalnu spoznaju. Osim toga šuma je svakome pred vratima i zato se može promatrati kao idealno mjesto učenja. Učenjem u šumi prenosi se znanje i sveobuhvatno iskustvo u prvom redu neposrednim razgledavanjem i osobnim doživljajem šume – glavom, srcem i rukama!. Pritom u prvom planu stoje iskustva osjeta i osjećaja uz prenošenje određenog znanja.',
    prImg1: pront1,
    prImg2: pront2,
  },

  {
    id: '20',
    projectImg: prontj4,
    title: 'Najkraće o šumskim dječjim vrtićima',
    dec: 'Šumski vrtići su koncept u kojem djeca uče o svijetu u slobodnom okolišu, na licu mjesta, jedno od drugoga i iz vlastitog iskustva. Razvijaju se diljem svijeta kao ozbiljna alternativa tradicionalnim dječjim vrtićima, a potaknuti su pojačanom brigom, prije svega urbanih roditelja za fizičko i mentalno zdravlje djece, ali i opsjednutosti sigurnošću i strogim propisima vezanima za izvanučioničke aktivnosti njihove djece u urbanim sredinama. Koncept se iz zapadnoeuropskih zemalja proširio i u druge europske zemlje te Kanadu i SAD. Posebno popularni šumski vrtići postaju u zemljama strogoće i discipline, Japanu i Južnoj Koreji. U Južnoj Koreji to je odgovor na najrigoroznije obrazovanje u svijetu, a u Japanu bijeg od strogih normi i stresnog pritiska visokotehnološki orijentiranog društva.',
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: '21',
    projectImg: prontj5,
    title: 'Što treba znati o šumskim radionicama?',
    dec: 'Šumske radionice su kao edukacijski oblik vrlo popularne zato što se drži da iskustvo osjetilno-doživljajnog boravka u šumi, više od drugih oblika edukacije, omogućuje djeci dojmljiv doživljaj i dodatnu emocionalnu povezanost s prirodom koja ih okružuje. U smislu ishoda edukacije, najučinkovitijima su se pokazali oblici koji spajaju tumačenje s fizičkim kretanjem kroz šumu. Takve aktivnosti jače potiču djecu u razvijanju poštovanja prema šumi, ali i buduće aktivno uključivanje u očuvanje prirode. Programi šumskih radionica kombiniraju metode stručnog vođenja i tumačenja, razgledavanja izložbi, demonstracije, praktične zadatke, igre, crtanje, pisanje, razgovor i usmeno izlaganje i dr. Naglasak je na osvješćivanju osjetila sudionika i njihovom potpunijem doživljavanju šume putem zvukova, zatvorenih očiju, raspoznavanjem vrste drveća po kori, listu i češeru, praćenjem životinjskih tragova ili pak savladavanjem neravnog šumskog terena i sl.',
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: '22',
    projectImg: prontj6,
    title: 'Zašto otpada lišće?',
    dec: 'Uzrok opadanju lišća nije jesenski vjetar. Naprotiv, otpadanje lišća je aktivni događaj koji omogućava drveću obnavljanje listova i uklanjanje nepotrebnih otpadnih tvari. Vidljiv znak opadanja lišća kod većine drveća su razne boje listova. Klorofil se prije otpadanja lista razlaže i njegovi vrijedni sastojci vraćaju se natrag u deblo. Tvari koje prate klorofil, crvenkasti karotenoidi i žuti ksantofili ostaju i listovima i daju im karakterističnu jesensku boju. Pazite, joha, jasen, bazga odbacuju zelene listove! Višak tvari u stablu i koje bi mu mogle postati teret (kao npr. kalcij) odvode se u listove i s njima odbacuju. Opadanjem listova upravljaju biljni hormoni. Na bazi peteljka listova stvara se posebno rastavno tkivo gdje se listovi odvajaju od grana. Ožiljci listova pokazuju mjesta na kojima su nekad bili listovi.',
    prImg1: pront1,
    prImg2: pront2,
  },

]

export default Projects;