import React from "react";
import Teams from '../../api/team'
import { Link } from 'react-router-dom'



const TeamSection2 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="wpo-team-area-s2 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            <span>Stručni tim</span>
                            <h2>Ovlašteni šumski pedagozi</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p> */}
                        </div>
                    </div>
                </div>
                <div className="wpo-team-wrap">
                    <div className="team-items">
                        <div className="row">
                            {Teams.slice(0, 8).map((Team, tm) => (
                                <div className="col-lg-3 col-md-6 col-12 mb-4" key={tm}>
                                    <div className="wpo-team-item" >
                                        <div className="wpo-team-img">
                                            <Link onClick={ClickHandler} to={`/team-single/${Team.id}`}>
                                                <img src={Team.tImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className="wpo-team-content" style={{ textAlign: "center" }}>
                                            <h2><Link onClick={ClickHandler} to={`/team-single/${Team.id}`}>{Team.name}</Link></h2>
                                            <span>{Team.title}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default TeamSection2;