import React, { Component } from 'react'
import emailjs from '@emailjs/browser';

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    state = {
        name: '',
        email: '',
        message: '',
        sending: false,
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        console.log("message", error);

        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            message, error } = this.state;

        if (name === '') {
            error.name = "Upište svoje ime i prezime";
        }
        if (email === '') {
            error.email = "Upišite email adresu";
        }

        if (message === '') {
            error.message = "Upišite poruku";

        }


        if (error && (error.name || error.email || error.message)) {
            this.setState({
                error
            });
        }
        // if (error.name === '' && error.email === '' && error.message === '') {
        //     this.setState({
        //         name: '',
        //         email: '',
        //         message: '',
        //         sending: false,
        //         error: {}
        //     })
        // }
        else {
            this.setState({ sending: true });

            emailjs.sendForm('service_70uj73m', 'template_56cidmu', this.form.current, '-mDi3q0bWlKKX4SZp')
                .then((result) => {
                    //poruka je poslana
                    setTimeout(() => {
                        this.setState({
                            name: '',
                            email: '',
                            message: '',
                            sending: false,
                            error: {}
                        });
                    }, 2500);
                    alert("Hvala! Poruka je poslana!")

                }, (error) => {
                    alert("GREŠKA SLANJA!\n" + error.text);
                    console.log(error.text);
                    this.setState({
                        sending: false,
                        error: error
                    });
                });
        }

    }

    render() {
        const { name,
            email,
            message,
            sending,
            error } = this.state;

        return (
            <form onSubmit={this.subimtHandler} className="form" ref={this.form}>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Ime i prezime" />
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email" />
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="message" placeholder="Poruka" value={message} onChange={this.changeHandler}></textarea>
                            <p>{error.message ? error.message : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn" disabled={sending}>{sending ? "Slanje poruke u tijeku... Pričekajte..." : "Pošalji"}</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}
export default ContactForm;