import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = (props) => {
    return (
        <div className="wpo-breadcumb-area">

            <div className="wpo-breadcumb-wrap">
                <h2 style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>{props.pageTitle}</h2>

            </div>


        </div>
    )
}

export default PageTitle;