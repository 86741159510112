import React from "react";
import { Link } from 'react-router-dom'


const Hero7 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-hero-section-7">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col col-xs-6 col-lg-6">
                        <div className="wpo-hero-section-text">
                            <div className="wpo-hero-title">
                                <h1>Silva.Rei</h1>
                                <h2>Centar za šumsku pedagogiju</h2>
                                {/* <h4>[ u osnivanju ]</h4> */}
                            </div>
                            <div className="wpo-hero-subtitle">
                                <p>Šumska pedagogija je specifičan koncept učenja „iz šume u šumi“ koji spaja učenje za ekološki odgoj, kretanje i obrazovanje, socijalno učenje i opće obrazovanje.</p>
                                <p>Šumska pedagogija jedno je od najboljih lica šumarstva. Osmišljena i vođena prvenstveno od stručnjaka sa  šumarsko-ekološkim znanjem i metodičko-didaktičkim iskustvom, šumska pedagogija je svojevrsna avantura između komunikacije i edukacije vezane uz šumu.</p>
                            </div>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="btn theme-btn-s2">Saznaj više</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero7;
