import React from 'react'
// import { Link } from 'react-router-dom'
import abimg from '../../images/ab.png'


const SvrhaUdruge = (props) => {

    return (
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="" />
                                {/* <div className="wpo-total-raised">
                                    <div className="wpo-total-raised-wrap">
                                        <div className="wpo-total-raised-icon">
                                            <i className="fi flaticon-wallet-filled-money-tool"></i>
                                        </div>
                                        <div className="wpo-total-raised-text">
                                            <ul>
                                                <li>Total Raised<span>$25000</span></li>
                                            </ul>
                                            <div className="progress-section">
                                                <div className="process">
                                                    <div className="progress">
                                                        <div className="progress-bar">
                                                            <div className="progress-value"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="round-ball-1"></div>
                                <div className="round-ball-2"></div>
                                <div className="round-ball-3"></div>
                                <div className="round-ball-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>Udruga "Silva.Rei"</span>
                            <h2>Svrha osnivanja udruge</h2>
                            <ul>
                                <li>popularizacija šumske pedagogije i šumskog odgoja za okoliš temeljenog na kvalificiranom strukovnom znanju i najboljoj šumarskoj praksi</li>
                                <li>promicanje održivog gospodarenja šumama kao primjera doprinosa postizanju okolišnih ciljeva i održivom razvoju</li>
                                <li>okupljanje šumarskih obrazovnih, znanstvenih i inženjerskih potencijala radi vjerodostojne komunikacije vrijednosti šume i sa šumama povezanih tema prema javnosti te prema općim i posebnim skupinama zainteresiranih </li>
                            </ul> 
                            {/*                           
                            <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">More About</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SvrhaUdruge;