import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import VideoSec from '../../components/VideoSec';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import Service from '../../components/Service';
import SvrhaUdruge from '../../components/SvrhaUdruge';
import FunFact from '../../components/FunFact';
import CauseSection from '../../components/CauseSection';
import TeamSection from '../../components/TeamSection';
import PartnerSection from '../../components/PartnerSection';
import AboutS2 from '../../components/AboutS2';



const AboutPage = () => {
    // useEffect(() => {
    //     setTimeout(() => {
    //         this.window.scroll(0);
    //     }, 500);
    // }, []);

    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'O čemu je riječ?'} pagesub={'Šumska pedagogija'} />
            {/* <VideoSec/> */}
            {/* <Service Fclass={'wpo-features-section-s2'}/> */}
            {/* <SvrhaUdruge/> */}
            <AboutS2 />
            <FunFact />
            {/* <CauseSection/>
            <TeamSection/>
            <PartnerSection tNone={'title-none'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
