import React from 'react' 
import Services from '../../api/service'
import ciljevi_img from '../../images/ciljevi.jpg'

const Service5 = (props) => {
    // const ClickHandler = () =>{
    //     window.scrollTo(10, 0);
    //  }

    return(
        <section className="wpo-features-section-s5 wpo-about-section-s6"  style={{marginBottom: "120px"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            {/* <span>Ciljevi</span> */}
                            {/* <h2>Koji su ciljevi udruge?</h2> */}
                            <h4>Šumska pedagogija temelji se na znanju o šumskim ekosustavima i iskustvu o održivom gospodarenju šumama. Ona potiče razumijevanje, interakciju i povezanost ljudi i okoliša u kontekstu održivog razvoja. Šumarskom pedagogijom šumska struka angažira i pridobiva mlade – oni su budući donositelji odluka i nasljednici brige o šumama.</h4>
                        </div>
                    </div>
                </div>
                <div className='wpo-about-items'>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <img src={ciljevi_img} alt="" />
                                    {/* <div className="pop-up-video">
                                        <VideoModal />
                                    </div>*/}
                                    <div className="shp-1"></div>
                                    <div className="shp-2"></div>
                                </div>
                   
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text" >
                                <ul style={{fontSize: "18px"}}>
                                    {Services.slice(13, 16).map((service, sitem) => (
                                        <li key={service.id} style={{marginTop: "18px"}}>{service.description}</li>
                                    ))}
                                </ul>
                                {/* <Link to="/goals" className="theme-btn-s2" >Saznaj više</Link> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service5;