 

import blogImg13 from "../images/blog/img-13.jpg";
import blogImg14 from "../images/blog/img-14.jpg";
import blogImg15 from "../images/blog/img-15.jpg";

import blogAvaterImg1 from "../images/team/7.jpg";
import blogAvaterImg2 from "../images/blog/blog-avater/img-2.jpg";
import blogAvaterImg3 from "../images/blog/blog-avater/img-3.jpg";
 
import blogSingleImg13 from "../images/blog/13.jpg";
import blogSingleImg14 from "../images/blog/14.jpg";
import blogSingleImg15 from "../images/blog/15.jpg";
 
const zanimljivosti = [  
    {
        id: 'z1',
        title: 'Zaboravne vjeverice spašavaju šume ',
        screens: blogImg13,
        description: 'Vjeverice su zaslužne za tisuće novih stabala koja niknu svake godine diljem svijeta tako što jednostavno zaborave gdje su zakopale žireve koje su planirale kasnije grickati. Zaboravnost sivih vjeverica, kako navode kanadski zoolozi, spasit će američke šume od nestajanja. Naime, vjeverice nađene orahe i žireve ostavljaju kao rezervu za zimu sakrivajući ih u iskopanim rupama na različitim mjestima po šumi. Međutim, sive vjeverice, za razliku od smeđih, zaboravljaju gdje su zakopale orahe pa iz njih izrasta novo drveće. Tako zaboravnost sivih vjeverica ne dopušta da iščeznu američke šume, zaključuju znanstvenici. Smeđe vjeverice skupljaju orahe tako da stvaraju zimsku rezervu puneći duplje stabala/drveća, ali nikada ne zaboravljaju gdje su ostavile zalihu pa iz tih oraha nikad ništa ne izraste, budući ih do proljeća pojedu.',
        author: 'Ivan Martinić',
        authorTitle:'prof.dr.sc.',
        authorImg:blogAvaterImg1,
        create_at: '04.01.2023.',
        blogSingleImg:blogSingleImg13, 
         blClass:'format-standard-image',
    },
    {
        id: 'z2',
        title: 'Indijanska legenda o lišću ',
        screens: blogImg14,
        description: 'Cherokee legenda kaže da je Veliki Duh dao svom drveću priliku, odnosno magijsku snagu da bude zeleno tijekom cijele godine. Međutim, tu će mogućnost dobiti samo ako ostane budno punih sedam dana i sedam noći. Dakako, nije sve drveće uspjelo ostati budno sedam dana i noći. Samo ono drveće koje je uspjelo ostati budno za nagradu je dobilo magične moći i zeleno je tijekom cijele godine, a ostalo drveće mijenja boju lišća koje na kraju i otpadne kako bi moglo spavati tijekom zime.',
        author: 'Ivan Martinić',
        authorTitle:'prof.dr.sc.',
        authorImg:blogAvaterImg1,
        create_at: '04.01.2023.',
        blogSingleImg:blogSingleImg14, 
        blClass:'format-standard-image',
    },
    {
        id: 'z3',
        title: 'Zašto je učenje u šumi o šumi važno i po čemu je posebno? ',
        screens: blogImg15,
        description: ' „U šumi ćete primijetiti stvari koje nikada ne biste primijetili na nastavi. Vidimo kako učenik s poteškoćama hoda nizbrdo, onaj koji ne želi zaprljati ruke, koji se svega boji, razuzdanog koji mora prilagoditi svoj ritam ritmu skupine, vođu koji može biti proaktivan i nezanimljiv. Skupljanje vrećice lišća i donošenje na nastavu drugačije je od gledanja na fotografijama. Usitnjavanje komada kremena poput primitivnog čovjeka oduševljava. Osim toga, možete pronaći zadatke koji angažiraju sve učenike, od onih s poteškoćama u učenju do onih koji su posebno zainteresirani i sjajnih promatrača.“ [zapisao jedan učitelj].',
        author: '[jedan učitelj]',
        authorTitle:'',
        authorImg:null,
        create_at: '04.01.2023.',
        blogSingleImg:blogSingleImg15,
         blClass:'format-video',
    },
];
export default zanimljivosti;