import eimg1 from '../images/event/img-1.jpg'
import eimg2 from '../images/event/img-2.jpg'
import eimg3 from '../images/event/img-3.jpg'
import eimg4 from '../images/event/img-4.jpg'
import eimg5 from '../images/event/img-5.jpg'
import eimg6 from '../images/event/img-6.jpg'
import eimg7 from '../images/event/img-7.jpg'
import eimg8 from '../images/event/img-8.jpg'
import eimg9 from '../images/event/img-9.jpg'

const Events = [
    {
        id: '1',
        eImg:eimg1,
        date:"25 Nov, 2021",
        eTitle: 'Help The Poor From Your Soal', 
        dec:'There are many variations of passages of Lorem Ipsum available.',
    },
    {
        id: '2',
        eImg:eimg2,
        date:"26 Nov, 2021",
        eTitle: 'Help Children Raise Out Of Proverty.', 
        dec:'There are many variations of passages of Lorem Ipsum available.',
    },
    {
        id: '3',
        eImg:eimg3,
        date:"27 Nov, 2021",
        eTitle: 'Provideing Education Is The Valuable Gift', 
        dec:'There are many variations of passages of Lorem Ipsum available.',
    },
    {
        id: '4',
        eImg:eimg4,
        date:"25 Nov, 2021",
        eTitle: 'Let’s Save The Rare Birds From Hunting.', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
        id: '5',
        eImg:eimg5,
        date:"26 Nov, 2021",
        eTitle: 'Protect The African Elephant.', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
        id: '6',
        eImg:eimg6,
        date:"27 Nov, 2021",
        eTitle: 'Save The Wildlife Animal.', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
        id: '7',
        eImg:eimg7,
        date:"23.6.2021.",
        eTitle: 'Radionica u Zagrebu', 
        dec:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        id: '8',
        eImg:eimg8,
        date:"31.8.2021.",
        eTitle: 'Radionica u NP Krka', 
        dec:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        id: '9',
        eImg:eimg9,
        date:"27 Nov, 2021",
        eTitle: 'Save The Wildlife Animal.', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
]

export default Events;