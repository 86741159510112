import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../../api/service'
import simg from '../../images/slider/right-img5.png'

const Service2 = (props) => {
    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (

        <section className="wpo-features-section-s3 section-padding wpo-about-section-s6">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            <span>Ciljevi</span>
                            <h2>Koji su ciljevi šumarske pedagogije?</h2>
                            <p>Šumska pedagogija temelji se na znanju o šumskim ekosustavima i iskustvu o održivom gospodarenju šumama. Ona potiče razumijevanje, interakciju i povezanost ljudi i okoliša u kontekstu održivog razvoja. Šumskom pedagogijom šumska struka angažira i pridobiva mlade – oni su budući donositelji odluka i nasljednici brige o šumama.</p>
                        </div>
                    </div>
                </div>
                <div className='wpo-about-items'>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <img src={props.abImg} alt="" />
                                    {/* <div className="pop-up-video">
                                        <VideoModal />
                                    </div>*/}
                                    <div className="shp-1"></div>
                                    <div className="shp-2"></div>
                                </div>
                   
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text" >
                                <ul style={{fontSize: "18px"}}>
                                    {Services.slice(13, 16).map((service, sitem) => (
                                        // <div className="col col-xl-4 col-lg-6 col-sm-6 col-12" key={sitem}>
                                        //     <div className="wpo-features-item">
                                        //         <div className="wpo-features-icon">
                                        //             <div className="icon">
                                        //                 <i className={`fi  ${service.fIcon1}`}></i>
                                        //             </div>
                                        //         </div>
                                        //         <div className="wpo-features-text">
                                        //             <h2><Link onClick={ClickHandler} to={`/service-single/${service.id}`}>{service.title}</Link></h2>
                                        //             <p>{service.description}</p>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <li key={service.id} style={{marginTop: "18px"}}>{service.description}</li>
                                    ))}
                                </ul>
                                <Link to="/goals" className="theme-btn-s2" >Saznaj više</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service2;