import React from 'react'
import { Link } from 'react-router-dom'
// import VideoModal from '../../components/ModalVideo'
import simg from '../../images/slider/right-img5.png'


const AboutS4 = (props) => {

    return (
        <section className={`wpo-about-section-s4 section-padding ${props.abClass}`}>
            <div className="container">
                <div className='wpo-about-items'>
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <img src={props.abImg} alt="" />
                                    {/* <div className="pop-up-video">
                                        <VideoModal />
                                    </div>*/}
                                    <div className="shp-1"></div>
                                    <div className="shp-2"></div>
                                </div>
                                <div className="ab-shape"><img src={simg} alt="" /></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <span>Udruga "Silva.Rei"</span>
                                <h2>Svrha osnivanja udruge</h2>
                                <ul style={{fontSize: "18px"}}>
                                    <li>popularizacija šumske pedagogije i šumskog odgoja za okoliš temeljenog na kvalificiranom strukovnom znanju i
                                        najboljoj šumarskoj praksi</li>
                                    <li>promicanje razumijevanja i primjene koncepta održivog razvoja kroz primjer održivog gospodarenja šumama</li>
                                    <li>okupljanje šumarskih obrazovnih, znanstvenih i inženjerskih potencijala radi vjerodostojne komunikacije vrijednosti
                                        šume i sa šumama povezanih tema prema javnosti i korisnicima</li>
                                </ul>
                                <Link to="/about" className="theme-btn-s2" >Saznaj više</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutS4;