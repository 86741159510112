import React from 'react' 

const AboutS2 = (props) => {

    return (
        <section className="wpo-about-section-s2 section-padding ">
            <div className="container">

                <div className="row align-items-center">

                    <div className="col-12">
                        <div className="wpo-about-text" style={{ maxWidth: "none" }}>

                            <span>Šumska pedagogija</span>
                            <h2>O čemu je riječ?</h2>
                            <p>Šumska pedagogija se temelji na osjetilnom sagledavanju prirode, doživljajnom upoznavanju života šume i iskustvenom učenju u šumi koje razvija sve aspekte ljudske osobnosti.</p>
                            <p>Šumska pedagogija želi putem izravno stečenih iskustava o ekološkim procesima, načelima  i odnosima u šumskim ekosustavima probuditi kod svih dobnih skupina razumijevanje interakcije ljudi i prirode i naročito razborito zaključivanje o optimalnim pristupima u gospodarenju šumama i šumskim resursima.</p>
                            <p>Šumska pedagogija promovira šumsko obrazovanje kao važan pedagoški koncept za obrazovanje o održivom razvoju:

                            </p>
                            <ul style={{ marginLeft: "60px" }}>
                                <li>najuspješniji koncept za prevladavanje hendikepa ‘odsustva prirode’ u razvoju, posebno urbanih dječjih populacija</li>
                                <li>doživljajno učenje temeljeno na senzibilizaciji osjetila (doživjeti šumu glavom, srcem i rukama)</li>
                                <li>orijentiran na angažiranje, akciju i avanturu</li>
                                <li>potiče neposredno iskustvo i preuzimanje  odgovornosti (prihvatljiv izazov i granice rizika)</li>
                                <li>važan dio procesa u postizanju ekološke zrelosti svakog pojedinca</li>
                            </ul>
                            <p>Šumska pedagogija unaprijeđuje socijalne i kreativne kompetencije pojedinaca svih dobi:</p>
                            <ul style={{ marginLeft: "60px" }}>
                                <li>entuzijazam za nova znanja</li>
                                <li>poticanje želje za istraživanjem</li>
                                <li>promicanje kreativnosti</li>
                                <li>razvoj empatije za šume i povezivanje s prirodom</li>
                                <li>razborito rasuđivanje, mudrost i osjećaj za  pravdu</li>
                            </ul>

                            <h2>Zašto je šumska pedagogija posebno važna za šumarstvo?</h2>
                            <p>Šumska pedagogija jedno je od najboljih lica šumarstva. Šumskom pedagogijom šumska struka angažira i pridobiva mlade – oni su budući donositelji odluka i nasljednici brige o šumama.</p>
                            <ul style={{ marginLeft: "60px" }}>
                                <li>podiže svijest, educira i informira javnosti o ulozi šuma i šumarskom gospodarstvu</li>
                                <li>vodi povećanju znanja, stvaranju pozitivnog stava i percepcije šuma i šumarskog sektora</li>
                                <li>promiče razumijevanje i primjenu koncepta održivog razvoja kroz primjer održivog gospodarenja šumama</li>
                                <li>šumarske stručnjake stavlja u ulogu autentičnih tumača  sveobuhvatne slike  života i funkcioniranja šumskih ekosustava</li>
                                <li>dobra je platforma za šumarske uprave kako bi društvu/javnosti približile ciljeve svog djelovanja u gospodarenju šumama</li>
                                <li>omogućuje da vlasnici šuma, šumarske uprave i tvrtke,  obrazovne i znanstvene institucije komuniciraju o gospodarenju šumama kroz aktivnosti šumske pedagogije</li>
                            </ul>
                            {/*                           
                            <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">More About</Link> */}
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default AboutS2;