import React from 'react';
import ContactForm from '../ContactFrom'


const Contactpage = () => {

    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-forest"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Udruga Silva.Rei</h2>
                                            <p>Centar za šumsku pedagogiju</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email</h2>
                                            <p>info@silvarei.org</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-location"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Adresa</h2>
                                            <p>Svetošimunska cesta 25, Zagreb</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Postavite nam pitanje...</h2>
                            {/* <p>Odgovaramo na sve upite, kritike i pohvale. Najlakši kontakt je putem priloženog online obrasca.</p> */}
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe title="frame-map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.3244269035504!2d16.027645851855134!3d45.824786717227234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d7c2ee94f219%3A0x57a25bd6a88f29c0!2sSveto%C5%A1imunska%20cesta%2025%2C%2010000%2C%20Zagreb!5e0!3m2!1shr!2shr!4v1647255200922!5m2!1shr!2shr"
                        loading="lazy"></iframe>
                </div>
            </section>
        </section>
    )

}

export default Contactpage;
