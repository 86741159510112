import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'
import ts4 from '../../images/testimonial/img-4.jpg'

class Testimonial2 extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonial = [
            {
                tsImg: ts2,
                Des: "Čovjeku ne treba ni pola sata<br/>Da jedno veće stablo posiječe<br/>A njemu, dok je toliko naraslo,<br/>Bilo je potrebno cijelo stoljeće.",
                Title: 'Eugen Roth',
                Sub: "(s njemačkoga prevela Nada Iveljić) ",
            },
            {
                tsImg: ts1,
                Des: "Čuvajmo Zemlju i prirodu na njoj, jer ih nismo naslijedili od svojih djedova i očeva, nego smo ih posudili od svojih potomaka.",
                Title: 'Tatanka Yotanka',
                Sub: "(Bik koji sjedi, poglavica Hunkpapa i vođa Siouxa) ",
            },
            {
                tsImg: ts3,
                Des: "Postoji izvrstan učitelj, ako ga razumijemo: to je priroda.",
                Title: 'Heinrich von Kleist',
                Sub: "(njemački književnik)",
            },
            {
                tsImg: ts4,
                Des: "Šuma je osebujni organizam neograničene dobrote i dobrohotnosti koji ne zahtjeva zahtjeve za njenim uzdržavanjem i velikodušno širi proizvode svoje životne aktivnosti, pruža zaštitu svim bićima, nudeći hlad čak i čovjeku sjekiri koji je uništava",
                Title: 'Gautama Buddha',
                Sub: "(osnivač budizma)",
            },
        ]
        return (

            <div className={`wpo-testimonial-area-s2 section-padding ${this.props.tClass}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="wpo-section-title">
                                <span>Edukativno</span>
                                <h2>Zašto je šuma istinska učionica?</h2>
                                <p>Mnogi ljudi kako prije, tako i sada promatraju šumu kao personifikaciju prirode. Zapravo ni jedna druga životna zajednica nije toliko podesna za neposredni doživljaj prirode, neograničeno pristupačna, a ljudima uz čisto prirodno-znanstvenu omogućuje i estetsku te emocionalnu spoznaju. </p>
                            </div>
                        </div>
                    </div>
                    <div className="wpo-testimonial-wrap">
                        <div className="testimonial-slider owl-carousel">
                        <Slider {...settings}>
                                {testimonial.map((tesmnl, tsm) => (
                                    <div className="wpo-testimonial-item" key={tsm}>
                                        <div className="wpo-testimonial-img">
                                            <img src={tesmnl.tsImg} alt="" />
                                        </div>
                                        <div className="wpo-testimonial-content">
                                            <p  dangerouslySetInnerHTML={{__html: tesmnl.Des}} />
                                            <h2>{tesmnl.Title}</h2>
                                            <span>{tesmnl.Sub}</span> 
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Testimonial2;